import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const Chart = ({ data }) => (
  <div style={{ width: '100%', height: '100vh' }}>
    <ResponsiveContainer width="100%" height="60%">
      <LineChart data={data}>
        <Line type="monotone" dataKey="Profit Amount" stroke="#5a9dd4" />
        <Line type="monotone" dataKey="Pool Size" stroke="#dd7b04" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

export default Chart;
